import React from 'react';
import style from './components/style.scss';
import { hot } from 'react-hot-loader/root';
import './global.scss';
import {
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import Content from './components/Content'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#D1F1F5',
      main: '#00BFD9',
      dark: '#0092A6',
      contrastText: '#fff',
    },
    secondary: {
      light: '#A9D4FF',
      main: '#59ACFF',
      dark: '#007DFA',
      contrastText: '#000',
    },
  },
  props: {
    MuiTextField: {
        variant: 'outlined'
    }
  }
});

// analytics
import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager'
//import googleAnalytics from '@analytics/google-analytics';
import fullStoryPlugin from '@analytics/fullstory';
import postHogPlugin from './components/postHogPlugin';
import Perfume from 'perfume.js';
import perfumePlugin from '@analytics/perfumejs';
import { AnalyticsProvider } from 'use-analytics';
import ReactHeap from 'reactjs-heap';
ReactHeap.initialize('2680675500');

const analytics = Analytics({
  app: 'app',
  plugins: [
    // googleAnalytics({
    //   trackingId: 'G-2493T4B6HQ' // app.unitedshops.org
    // }),
    googleTagManager({
      containerId: 'G-2493T4B6HQ'
    }),
    postHogPlugin({
      token: 'phc_y4sapItNfSM3XsGA92br5tV5hEj7xBPX9w1kD0rIZHO',
      api_host: 'https://app.posthog.com'
    }),
    fullStoryPlugin({
      org: '16DCCA'
    }),
    // page load performance tracking
    // perfumePlugin({
    //   // Perfume.js class. If empty, window.Perfume will be used.
    //   perfume: Perfume,
    //   // Analytics providers to send performance data.
    //   destinations: {
    //     // perf data will sent to Google Analytics
    //     'google-analytics': true,
    //   },
    // }),
  ]
});

import * as Sentry from "@sentry/react";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Sentry.ErrorBoundary>
          <MuiThemeProvider theme={theme}>
            <AnalyticsProvider instance={analytics}>
              <Router>
                <Content />
              </Router>
            </AnalyticsProvider>
          </MuiThemeProvider>
        </Sentry.ErrorBoundary>
      </React.Fragment>
    );
  }
}

export default Sentry.withProfiler(hot(App));
