import React from "react";
import useWindowScroll from "@react-hook/window-scroll";
import { styles } from "./theme";
import { Masonry, useInfiniteLoader } from "masonic";
import axios from "axios";
import {
  api_call
} from './api_endpoints';
import Menu from './Menu';
import {
  format_currency,
  capitalize,
  to_title_case,
  wait
} from './util';

import MenuIcon from '@material-ui/icons/Menu';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import LinkIcon from '@material-ui/icons/Link';
import ShareIcon from '@material-ui/icons/Share';

export default function Products (props) {
  console.log('props', props);
  
  let
  page = 1,
  tag = Object.fromEntries(new URLSearchParams(props.location.search)).tag; //props.match.params.tag;

  tag.match(/_/) != null ? tag = tag.replace(/_/g, ' ') : null;

  const [items, setItems] = React.useState([]);
  const [likes, set_likes] = React.useState([]);
  const [menu, set_menu] = React.useState(false);
  const [error, set_error] = React.useState(false);
  const [loading, set_loading] = React.useState({});

  React.useEffect(() => {
    set_error(false);
    let get_likes = localStorage.getItem('unitedshops_likes');
    console.log('INITIAL get_likes', get_likes);
    if (get_likes) {
      let parsed_likes = JSON.parse(get_likes);
      console.log('INITIAL parsed_likes', parsed_likes);
      if (parsed_likes && tag != 'likes' && parsed_likes[tag]) {
        console.log('INITIAL parsed_likes[tag]', parsed_likes[tag]);
        set_likes(parsed_likes[tag]);
      }
      if (tag === 'likes') {
        console.log('parsed_likes', parsed_likes);
        let all_likes = [];
        Object.entries(parsed_likes).map(([k1,v1]) => {
          all_likes = [...all_likes, v1].flat();
        });
        console.log('all_likes', all_likes);

        if (all_likes && all_likes.length === 0) {
          set_error("You don't have any likes yet. Your liked products will appear here.")
          return;
        }

        let shown_products = [];
        (async function () {
          let get_data = await api_call ('unitedshops', 'products_get', {
            products: all_likes
          });
          if (get_data && get_data.products && get_data.products.length > 0) {
            let pulled_products = get_data.products;
            console.log('get_data.products', get_data.products);
            all_likes.map(l => {
              shown_products.push(pulled_products.filter(item => item.product_id === l)[0]);
            });
            console.log('shown_products', shown_products);
            setItems(shown_products);
          } else {
            set_error('Products not found.');
          };
        })();
      }
    } else if (tag === 'likes') {
      set_error("You don't have any likes yet. Your liked products will appear here.")
    }
    if (tag === 'likes') {
      return;
    }
    (async function () {
      set_loading({
        loading_initial: true
      });
      let get_data = await api_call ('unitedshops', 'classified_get', {
        tag: tag,
        page: "1",
        per_page: "100",
        group: "umd"
      });
      if (get_data && get_data.products && get_data.products.length > 0) {
        setItems(get_data.products);
        set_loading({});
      } else {
        set_loading({});
        set_error('Products not found. Try another tag.');
      };
    })();
  }, []);

  React.useEffect(() => {
    console.log('likes', likes);
    
    let get_likes = localStorage.getItem('unitedshops_likes');
    console.log('get_likes', get_likes);

    if (get_likes) {
      let parsed_likes = JSON.parse(get_likes);
      console.log('parsed_likes', parsed_likes);
      console.log('parsed_likes[tag]', parsed_likes[tag]);

      if (parsed_likes && parsed_likes[tag]) {
        let
        tag_likes = parsed_likes[tag],
        updated_likes = [...new Set([likes])];
        localStorage.setItem('unitedshops_likes', JSON.stringify({
          ...parsed_likes,
          [tag]: likes,
        }));
        
      } else {
        localStorage.setItem('unitedshops_likes', JSON.stringify({
          ...parsed_likes,
          [tag]: likes,
        }));
      } 

    } else if (tag != 'likes') {
      localStorage.setItem('unitedshops_likes', JSON.stringify({
        [tag]: likes
      }));
    }

  }, [likes]);

  async function get_data(page) {
    set_loading({
      loading_more: true
    });
    console.log("page", page);
    let get_data = await api_call ('unitedshops', 'classified_get', {
      tag: tag,
      page: page,
      per_page: "100",
      group: "umd"
    });
    if (get_data && get_data.products && get_data.products.length > 0) {
      set_loading({});
      return get_data.products;
    } else {
      set_error('There are no more products.');
    };
  };

  React.useEffect(() => {
    (async function () {
      console.log('items useeffect', items.length);
    })();
  }, [items]);
  const maybeLoadMore = useInfiniteLoader(
    async (page) => {
      const nextItems = tag != 'likes' ? await get_data(++page) : null; // await getFakeItemsPromise(startIndex, stopIndex);
      setItems((current) => [...current, ...nextItems]);
    },
    {
      isItemLoaded: (index, items) => !!items[index],
      minimumBatchSize: 32,
      threshold: 6
    }
  );

  //console.log(await get_data());

  // cannot use loading="lazy" or any other lazy-loading methods because we depend on image loading to exclude removed products since their respective images will be deleted and not load
  const product_component = ({ data: { product_id, title, image_1, price, domain, handle } }) => (
    <div className={`${style("card")} p-item`}>
      <a href={`https://${domain}/products/${handle}`} target={`_blank`}>
      <img className={`${style("img")} p-image`} src={image_1} 
      onError={(e)=>{ setItems(items.filter(obj => obj.product_id != product_id)) }}
      //onLoad={()=>{console.log('onLoad', image_1)}}
      />
      </a>

      {/* <span children={product_id} /> */}
      <span className={`p-like`} onClick={()=>{
        if (likes.includes(product_id)) {
          set_likes(likes.filter(item => item != product_id));
        } else {
          set_likes([product_id, ...likes])
        }
      }}>
        {tag != 'likes' && 
          <>
          {likes && likes.includes(product_id) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </>
        }
      </span>
      <span className={`p-title`}>
        <a className={`p-link`} href={`https://${domain}/products/${handle}`}>{title}</a>
      </span>
      <span className={`p-price`}>{format_currency(price)}</span>
      <span className={`p-domain`}>
        <a className={`p-link`} href={`https://${domain}`}>{domain}</a>
      </span>

    </div>
  );

  const Header = () => {
    const scrollY = useWindowScroll(5);
    return (
      <>
        <div className={`${style("header", scrollY > 64 && "minify")} header`}>
          <div className={`header-container`}>
            <div className={`link link-menu`} onClick={() => {set_menu(!menu)}}>
              <MenuIcon />
            </div>
            <h1 className={`disable-selection`}><a id="logo" href={`/`}>United Shops</a>
              <span className={`tag-title`}>{to_title_case(tag)}</span>
            </h1>
            <div className={`link link-likes`}>
              <a href={`/products?tag=likes`}>
              <FavoriteIcon />
              </a>
            </div>
          </div>
        </div>
      </>
    );
  };
  

  return (
    <>
    <main className={style("container")} onClick={()=>{}}>
      <div className={style("masonic")}>
        {error && <div className={`error-message`}>{error}</div>}
        {!error && <Masonry
          onRender={tag != 'likes' ? maybeLoadMore : null}
          items={items}
          columnGutter={15}
          columnWidth={300}
          overscanBy={2}
          render={product_component}
        />}
      </div>
      <Header />
      <div className={`side-menu ${menu ? 'active' : ''}`}>
        <Menu />
      </div>
      { loading && loading.loading_initial && <div className={`loading`}>Loading...</div> }
      { loading && loading.loading_more && <div className={`loading-more`}>Loading More...</div> }
    </main>
    </>
  );
};

const style = styles({
  masonic: `
    padding: 8px;
    width: 100%;
    max-width: 1280px;
    margin: 100px auto;
  `,
  container: `
    min-height: 100vh;
    width: 100%;
  `,
  minify: ({ pad, color }) => `
    padding: ${pad.md};
    background-color: ${color.dark};
    color: ${color.light};
  `,
  header: ({ pad, color }) => `
    color: ${color.body};
  `,
  card: ({ shadow, color, pad, radius }) => `
    display: flex;
    flex-direction: column;
    background: #eee;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    transition: transform 100ms ease-in-out;
    width: 100%;
    border: 1px solid #ccc;
    color: #444;

    span:last-of-type {
      color: #444;
      padding: ${pad.md};
    }

    &:xhover {
      position: relative;
      background: ${color.light};
      transform: scale(1.125);
      z-index: 1000;
      box-shadow: ${shadow.lg};

      span:last-of-type {
        color: ${color.dark};
        padding: ${pad.md};
      }
    }
  `,
  img: ({ radius }) => `
    width: 100%;
    display: block;
    border-top-left-radius: ${radius.md};
    border-top-right-radius: ${radius.md};
    display: block;
  `
});