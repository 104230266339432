import axios from 'axios';
import {
  lowercase_obj_values,
  strigify_nested_obj
} from './util'
import * as Sentry from "@sentry/react";

const api = {
  env: 'prod',  // local, prod
  unitedshops: {
    local: 'http://localhost:4000',
    dev: 'https://api-dev.unitedshops.org',
    prod: 'https://api.unitedshops.org'
  },
  version: '/v1/',
  endpoints: {
    // unitedshops
    tags_get: 'tags/get',
    products_get: 'products/get',
    labeling_count: 'labeling/count',
    labeling_get: 'labeling/get',
    labeling_create: 'labeling/create',
    labeled_get: 'labeled/get',
    labeled_remove: 'labeled/remove',
    amazon_all: 'amazon/all',
    amazon_filtered: 'amazon/filtered',
    test_create: 'test/create',
    test_get: 'test/get',
    test_remove: 'test/remove',
    classified_get: 'classified/get',
    classified_assess: 'classified/assess',
  }
}
const
env = api.env;

function api_base(system) {
  let start = api[system];
  return start[api.env]+api.version;
};

async function api_call (system, endpoint, obj) {
  console.log('api_call', system, endpoint, obj);

  let endpoints_list = []
  Object.entries(api.endpoints).forEach(([key, value]) => {
    endpoints_list.push(key);
  });
  //console.log('endpoints_list', endpoints_list);

  let endpoint_url;
  if (endpoints_list.includes(endpoint)) {
    endpoint_url = api_base(system)+api.endpoints[endpoint];
  } else {
    endpoint_url = endpoint;
  }

  return await axios.post(endpoint_url, strigify_nested_obj(lowercase_obj_values(obj)), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(function(response) {
    //console.log('response', response);
    if (response && response.status === 200 && response.data && response.data.response_status === 'success') {
      console.log(endpoint, response.data);
      return response.data;
    } else {
      //console.log('error', response);
      Sentry.captureException(response);
      return response;
    }
    //setLoading(false);
    return response;
  })
  .catch(function(error) {
    console.log(error);
    //setLoading(false);
    Sentry.captureException(error);
    return error;
  });
};

export {env, api, api_base, api_call}