import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App.jsx';

import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

import {
  env
} from './components/api_endpoints'

Sentry.init({
  dsn: 'https://a7e3fa7dac204ff794a704f44d8e740f@o303909.ingest.sentry.io/6051830',
  whitelistUrls: [
    /https?:\/\/((api|api-dev)\.)?unitedshops\.org/
  ],
  ignoreErrors: [
    /network/gi, // network error
    /request/gi, // request aborted, request failed
    /timeout/gi, // timeout error
    /promise/gi, // non-error promise rejection
    /illegal/gi // illegal invocation, illegal constructor
  ],
  integrations: [new Integrations.BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
  })],
  tracesSampleRate: 1.0, // Be sure to lower this in production (lower non-zero value, perhaps 0.1)
  environment: env
});

ReactDOM.render(
  <>
    <Router history={history}>
      <App />
    </Router>
  </>, document.getElementById('app'));
