import React from "react";
import style from './style.scss';
import {
  api_call
} from './api_endpoints';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as LinkTo,
  useLocation,
  useParams,
  useHistory
} from 'react-router-dom';
import {
  format_currency,
  capitalize,
  wait
} from './util';
import Menu from './Menu';

export default function Start () {

  React.useEffect(() => {
    (async function () {
      
    })();
  }, []);

  return (
    <>
      <div className={`center-content`}>
      <div className={`center-container`}>
      <h1 className={`disable-selection`}>United Shops</h1>
      <p>United Shops is a non-profit marketplace with millions of products from thousands of stores. <br/><br/> Our mission is to empower small businesses to reach a wider audience and enable shoppers to discover great products from a larger selection.<br/><br/>Get started by selecting a tag below.</p>
      <div className={`center-it`}>
      <Menu />
      </div>
      </div>
      </div>
    </>
  );
};