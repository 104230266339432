import React from 'react';
import style from './style.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as LinkTo,
  useLocation,
  useParams,
  useHistory
} from 'react-router-dom';
import {
  useLocalStorage,
  writeStorage
} from '@rehooks/local-storage';
import { useAnalytics } from 'use-analytics';
import { useScrollTracker } from 'react-scroll-tracker';

// import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
// const queryClient = new QueryClient();

//import Login from './Login';
import Labeling from './Labeling';
import Products from './Products';
import Menu from './Menu';
import Start from './Start';

export default function Content() {
  const location = useLocation();
  const analytics = useAnalytics();
  //const [scrollDepth, setScrollDepth] = React.useState(0);
  
  // useScrollTracker([25, 50, 75, 100], ({ scrollY }) => {
  //   setScrollDepth(scrollY);
  //   // console.log('scroll depth reached:', scrollY);
  // });

  React.useEffect( () => {
    analytics.page()
  }, [location]);

  // analytics.on('track', ({ payload }) => {
  //   //console.log('analytics.track', payload);
  // })

  return (
    <React.Fragment>

      <Switch>
        <Route path="/" exact component={Start} />
        {/* <Route path="/labeling" component={Labeling} /> */}
        <Route path="/products" component={Products} />
        {/* <Route path="/menu" component={Menu} /> */}
      </Switch>

    </React.Fragment>
  )
};