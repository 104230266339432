import React from "react";
import axios from "axios";
import style from './style.scss';
import {
  api_call
} from './api_endpoints';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as LinkTo,
  useLocation,
  useParams,
  useHistory
} from 'react-router-dom';
import {
  format_currency,
  capitalize,
  wait
} from './util';

/*
let menu_data = {
  "apparel": {
    "top": {
      "t-shirt":'',
      "sweater":'',
      "camisole":'',
      "hoodie":'',
      "fleece":'',
      "jacket":'',
      "coat":'',
      "blazer":'',
      "vest":'',
      "turtleneck":''
    },
    "bottom": {
      "shorts":'',
      "jeans":'',
      "pants":'',
      "joggers":'',
      "sweatpants":'',
      "leggings":'',
      "skirt":'',
      "stockings":''
    },
    "outfit": {
      "suit":'',
      "jumpsuit":'',
      "romper":''
    },
    "dress": {
      "bodycon":'',
      "maxi":'',
      "gown":'',
      "formal dress":'',
      "bridal dress":'',
      "mini dress":'',
      "maxi dress":''
    },
    "lingerie": {
      "bras":'',
      "panties":'',
      "thong":'',
      "g-string":'',
      "bikini":'',
      "bodysuit":'',
      "chemise":'',
      "babydoll":'',
      "corset":''
    },
    "shoes": {
      "sneakers":'',
      "oxfords":'',
      "loafers":'',
      "flats":'',
      "stilettos":'',
      "wedges":'',
      "boots":'',
      "slippers":'',
      "sandals":''
    },
    "socks": {
      "invisible socks":'',
      "ankle socks":'',
      "crew socks":'',
      "tube socks":''
    },
    "handbags": {
      "clutch bag":'',
      "tote":'',
      "crossbody":'',
      "satchel":'',
      "duffel":''
    },
    "hats": {
      "baseball cap":'',
      "visor":'',
      "beanie":'',
      "fedora":'',
      "cowboy":'',
      "beret":'',
      "headband":''
    },
    "jewelry": {
      "ring":'',
      "earrings":'',
      "bracelet":'',
      "necklace":'',
      "cufflinks":''
    },
    "travel": {
      "suitcase":'',
      "luggage":'',
      "backpacks":'',
      "briefcase":''
    },
    "accessories": {
      "sunglasses":'',
      "tie":'',
      "bow tie":'',
      "belt":'',
      "gloves":'',
      "umbrella":''
    }
  },
  "cosmetics": {
    "face": {
      "foundation":'',
      "face powder":'',
      "concealer":'',
      "face primer":'',
      "bronzer":'',
      "contouring":'',
      "glitter":'',
      "makeup remover":'',
      "face wiper":'',
      "face mask":''
    },
    "lips": {
      "lip primer":'',
      "lipstick":'',
      "lip gloss":'',
      "lip liner":'',
      "lip stain":'',
      "lip plumper":'',
      "lip balm":''
    },
    "eyes": {
      "mascara":'',
      "eyeliner":'',
      "eyeshadow":'',
      "eyelash":'',
      "eyebrow":''
    },
    "makeup tool": {
      "makeup brush":'',
      "makeup sponge":'',
      "makeup applicator":'',
      "blotting paper":'',
      "makeup mirror":''
    },
    "hair": {
      "shampoo":'',
      "conditioner":'',
      "hairspray":'',
      "hair gel":'',
      "pomade":'',
      "hair brush":'',
      "hair comb":'',
      "hair straighter":'',
      "hair relaxer":'',
      "hair bleaches":'',
      "hair dye":'',
      "hair extensions":'',
      "wig":'',
      "hair trimmer":''
    },
    "nails": {
      "nail clippers":'',
      "cuticle cutter":'',
      "nail filer":'',
      "nail paint":'',
      "nail polish":''
    },
    "skincare": {
      "cleanser":'',
      "towelettes":'',
      "exfoliator":'',
      "moisturizer":'',
      "lotion":'',
      "serum":'',
      "sunscreen":'',
      "acne treatment":''
    },
    "shaving": {
      "razor":'',
      "shaving cream":'',
      "aftershave":'',
      "waxing":''
    },
    "fragrance": {
      "deodorant":'',
      "perfume":'',
      "cologne":''
    }
  },
  // "home": {
  //   "appliances": {
  //     "laundry washer":'',
  //     "dishwasher":'',
  //     "laundry dryer":'',
  //     "refrigerator":'',
  //     "freezer":'',
  //     "space heater":'',
  //     "oscillating fan":'',
  //     "ceiling fan":'',
  //     "humidifier":'',
  //     "dehumidifier":'',
  //     "air purifier":'',
  //     "cooktop":'',
  //     "portable stove":'',
  //     "toaster oven":'',
  //     "microwave":''
  //   },
  //   "kitchen": {
  //     "pop up toaster":'',
  //     "coffee maker":'',
  //     "mixer":'',
  //     "food processor":'',
  //     "coffee grinder":'',
  //     "blender":'',
  //     "juicer":'',
  //     "cooker":'',
  //     "food steamer":'',
  //     "fryer":'',
  //     "dehydrator":'',
  //     "food chopper":'',
  //     "food slicer":'',
  //     "dish rack":'',
  //     "fruit basket":'',
  //     "trash can":''
  //   },
  //   "cookware": {
  //     "cooking pan":'',
  //     "cooking pot":'',
  //     "cast iron skillet":'',
  //     "braiser":'',
  //     "wok":'',
  //     "baking tray":'',
  //     "mesh strainer":'',
  //     "colander":''
  //   },
  //   "containers": {
  //     "jar":'',
  //     "food storage container":'',
  //     "kettle":'',
  //     "pitcher":'',
  //     "jug":'',
  //     "bottle":'',
  //     "storage box":''
  //   },
  //   "utensils": {
  //     "plastic cutlery":'',
  //     "wood cutlery":'',
  //     "metal cutlery":'',
  //     "knives set":'',
  //     "long knives":'',
  //     "spatula":'',
  //     "solid turner":'',
  //     "slotted turner":'',
  //     "ladle":'',
  //     "draining spoon":'',
  //     "tongs":'',
  //     "whisk":'',
  //     "mesh skimmer":'',
  //     "peeler":'',
  //     "grater":'',
  //     "can opener":'',
  //     "pasta server":'',
  //     "ice cream scoop":'',
  //     "pizza cutter":'',
  //     "corkscrew":'',
  //     "garlic crusher":'',
  //     "rolling pin":'',
  //     "measuring cups":''
  //   },
  //   "tableware": {
  //     "mixing bowls":'',
  //     "paper bowls":'',
  //     "plastic bowls":'',
  //     "wood bowls":'',
  //     "ceramic bowls":'',
  //     "metal bowls":'',
  //     "paper plates":'',
  //     "plastic plates":'',
  //     "wood plates":'',
  //     "ceramic plates":'',
  //     "metal food plates":'',
  //     "paper cups":'',
  //     "plastic cups":'',
  //     "wood cups":'',
  //     "glass cups":'',
  //     "metal cups":'',
  //     "glass mug":'',
  //     "ceramic mug":'',
  //     "insulated mug":'',
  //     "serving tray":'',
  //     "charcuterie":''
  //   },
  //   "furniture": {
  //     "couch":'',
  //     "ottoman table":'',
  //     "coffee table":'',
  //     "glass table":'',
  //     "desk":'',
  //     "cabinet":'',
  //     "bench":'',
  //     "chair":'',
  //     "stool":'',
  //     "outdoor furniture":''
  //   },
  //   "bedroom": {
  //     "bedframe":'',
  //     "mattress":'',
  //     "bed topper":'',
  //     "dresser":'',
  //     "nightstand":'',
  //     "headboard":'',
  //     "blanket":'',
  //     "duvet":'',
  //     "quilt":'',
  //     "pillow":'',
  //     "pillowcase":'',
  //     "bed sheet":''
  //   },
  //   "bathroom": {
  //     "adult bathrobe":'',
  //     "towel":'',
  //     "bath vanity":'',
  //     "toilet":'',
  //     "bathtub":'',
  //     "showerhead":'',
  //     "hamper":'',
  //     "weight scale":'',
  //     "bath sliding door":''
  //   },
  //   "cleaning": {
  //     "hand soap":'',
  //     "dish soap":'',
  //     "all purpose cleaner":'',
  //     "glass cleaner":'',
  //     "detergent":'',
  //     "toothbrush":'',
  //     "toothpaste":'',
  //     "mouthwash":'',
  //     "floss":'',
  //     "hand sanitizer":'',
  //     "cleaning sponge":'',
  //     "wipes":'',
  //     "cleaning cloth":'',
  //     "vacuum":'',
  //     "broom":'',
  //     "sweeper":'',
  //     "duster":'',
  //     "mop":'',
  //     "spray bottle":''
  //   },
  //   "decor": {
  //     "interior paint":'',
  //     "decals":'',
  //     "wall painting":'',
  //     "picture frame":'',
  //     "curtains":'',
  //     "blinds":'',
  //     "wall mirror":'',
  //     "candle":'',
  //     "sculpture":'',
  //     "wall clock":''
  //   },
  //   "lighting": {
  //     "bulb":'',
  //     "led strip":'',
  //     "lamp":'',
  //     "light fixture":'',
  //     "chandelier":'',
  //     "light switch":''
  //   },
  //   "flooring": {
  //     "rug":'',
  //     "stick tiles":'',
  //     "door stopper":''
  //   },
  //   "backyard": {
  //     "grill":'',
  //     "gazebo":'',
  //     "canopy":'',
  //     "camping tent":'',
  //     "pergola":'',
  //     "swimming pool":'',
  //     "hot tub":'',
  //     "hammock":'',
  //     "swing":'',
  //     "trampoline":'',
  //     "shed":'',
  //     "garden fence":''
  //   },
  //   "tools": {
  //     "flashlight":'',
  //     "construction light":'',
  //     "multitool":'',
  //     "pliers":'',
  //     "screwdriver":'',
  //     "wrench":'',
  //     "hammer":'',
  //     "chisel":'',
  //     "c-clamp":'',
  //     "spring clamp":'',
  //     "drill":'',
  //     "hand saw":'',
  //     "circular saw":'',
  //     "sander":'',
  //     "angle grinder":'',
  //     "lawn mower":'',
  //     "leaf blower":'',
  //     "hedge trimmer":'',
  //     "string trimmer":'',
  //     "pruning shears":'',
  //     "garden shovel":'',
  //     "snow shovel":'',
  //     "garden hose":'',
  //     "step ladder":'',
  //     "bucket":'',
  //     "toolbox":''
  //   }
  // },
  // "electronics": {
  //   "gadgets": {
  //     "smartphone":'',
  //     "tablet":'',
  //     "phone stand":'',
  //     "phone car mount":'',
  //     "tablet stand":'',
  //     "headphone":'',
  //     "speaker":'',
  //     "drone":'',
  //     "voice recorder":'',
  //     "thermostat":'',
  //     "power strip":'',
  //     "alarm clock":'',
  //     "tv":'',
  //     "tv mount":'',
  //     "batteries":''
  //   },
  //   "computers": {
  //     "laptop":'',
  //     "desktop":'',
  //     "laptop bag":'',
  //     "computer case":'',
  //     "monitor":'',
  //     "monitor mount":'',
  //     "mouse":'',
  //     "keyboard":'',
  //     "usb flash drive":'',
  //     "sd card":'',
  //     "micro sd card":'',
  //     "ssd":'',
  //     "hdd":'',
  //     "motherboard":''
  //   },
  //   "cameras": {
  //     "compact camera":'',
  //     "dslr camera":'',
  //     "security camera":''
  //   },
  //   "office": {
  //     "router":'',
  //     "nas":'',
  //     "printer":'',
  //     "projector":'',
  //     "voip phone":'',
  //     "shredder":''
  //   }
  // },
  // "baby": {
  //   "wear": {
  //     "baby sleeper":'',
  //     "swaddle":''
  //   },
  //   "room": {
  //     "crib":'',
  //     "bassinet":'',
  //     "baby rocker":''
  //   },
  //   "accessories": {
  //     "pacifier":''
  //   },
  //   "mobility": {
  //     "baby carrier":'',
  //     "car seat":'',
  //     "stroller":''
  //   }
  // },
  // "pets": {
  //   "food": {
  //     "pet treat":'',
  //     "dog food":'',
  //     "cat food":''
  //   },
  //   "space": {
  //     "dog bed":'',
  //     "litter box":'',
  //     "bird cage":'',
  //     "aquarium":'',
  //     "terrarium":''
  //   },
  //   "accessories": {
  //     "pet toys":'',
  //     "feeder":'',
  //     "collar":'',
  //     "leash":''
  //   }
  // },
  // "hobbies": {
  //   "sports": {
  //     "basketball hoop":'',
  //     "basketball ball":'',
  //     "football ball":'',
  //     "football helmet":'',
  //     "soccer ball":'',
  //     "baseball ball":'',
  //     "cleats":'',
  //     "bat":'',
  //     "racquet":'',
  //     "bike":'',
  //     "scooter":'',
  //     "bike helmet":'',
  //     "motorcycle":'',
  //     "skateboard":'',
  //     "surfboard":'',
  //     "kayak":'',
  //     "ping pong table":'',
  //     "ping pong paddle":'',
  //     "ping pong ball":'',
  //     "snowboard":'',
  //     "skis":''
  //   },
  //   "gym": {
  //     "treadmill":'',
  //     "elliptical":'',
  //     "stepper":'',
  //     "dumbbell":'',
  //     "barbell":'',
  //     "kettlebell":'',
  //     "ab roller":'',
  //     "resistance band":'',
  //     "jump ropes":'',
  //     "exercise ball":'',
  //     "foam roller":''
  //   },
  //   "games": {
  //     "puzzle":'',
  //     "chess":'',
  //     "checkers":'',
  //     "scrabble":'',
  //     "jenga":'',
  //     "monopoly":'',
  //     "battleship":'',
  //     "twister":''
  //   },
  //   "music": {
  //     "piano":'',
  //     "guitar":'',
  //     "cello":'',
  //     "flute":'',
  //     "violin":'',
  //     "trumpet":'',
  //     "clarinet":'',
  //     "saxophone":'',
  //     "drum":'',
  //     "harp":''
  //   },
  //   "crafts": {
  //     "scissors":'',
  //     "acrylics":'',
  //     "pastel":'',
  //     "ribbon":'',
  //     "stickers":''
  //   }
  // },
  // "drinks": {
  //   "beverages": {
  //     "coffee":'',
  //     "tea":'',
  //     "juice":'',
  //     "soda":'',
  //     "sparkling water":''
  //   },
  //   "alcohol": {
  //     "beer":'',
  //     "wine":'',
  //     "champagne":'',
  //     "whiskey":'',
  //     "gin":'',
  //     "vodka":'',
  //     "rum":'',
  //     "brandy":'',
  //     "tequila":'',
  //     "absinthe":'',
  //     "everclear":''
  //   }
  // },
  // "food": {
  //   "snacks": {
  //     "cookies":'',
  //     "crackers":'',
  //     "muffin":'',
  //     "pretzel":'',
  //     "pudding":'',
  //     "gelatin":'',
  //     "jerky":'',
  //     "chips":'',
  //     "candy":'',
  //     "gum":''
  //   },
  //   "spread": {
  //     "syrup":'',
  //     "honey":'',
  //     "jelly":'',
  //     "jam":''
  //   },
  //   "bread": {
  //     "loaf":'',
  //     "tortilla":'',
  //     "bagel":'',
  //     "croissant":'',
  //     "baguette":'',
  //     "biscuit":'',
  //     "naan":''
  //   },
  //   "breakfast": {
  //     "oatmeal":'',
  //     "granola":'',
  //     "cereal":'',
  //     "pancake":'',
  //     "waffles":''
  //   },
  //   "rice": {
  //     "basmati":'',
  //     "rosematta":'',
  //     "parboiled":'',
  //     "valencia":'',
  //     "camargue":''
  //   },
  //   "italian": {
  //     "pasta":'',
  //     "gnocchi":'',
  //     "spaghetti":'',
  //     "penne":'',
  //     "macaroni":'',
  //     "pizza":'',
  //     "flatbread":''
  //   },
  //   "mexican": {
  //     "burrito":'',
  //     "enchilada":'',
  //     "quesadilla":'',
  //     "fajita":''
  //   },
  //   "spices": {
  //     "pepper":'',
  //     "paprika":'',
  //     "chili":'',
  //     "oregano":'',
  //     "cumin":'',
  //     "coriander":'',
  //     "basil":'',
  //     "curry":'',
  //     "cinnamon":'',
  //     "tumeric":'',
  //     "thyme":'',
  //     "parsley":'',
  //     "ginger":'',
  //     "cardamom":'',
  //     "mint":''
  //   },
  //   "nuts": {
  //     "peanuts":'',
  //     "almond":'',
  //     "pecan":'',
  //     "cashew":'',
  //     "walnut":'',
  //     "chestnut":'',
  //     "hazelnut":'',
  //     "pistachio":'',
  //     "pine":''
  //   },
  //   "diary": {
  //     "milk":'',
  //     "cheese":'',
  //     "yogurt":'',
  //     "butter":''
  //   },
  //   "dessert": {
  //     "pie":'',
  //     "baklava":'',
  //     "crepe":'',
  //     "paczki":'',
  //     "donut":'',
  //     "parfait":'',
  //     "cake":'',
  //     "creme":'',
  //     "brulee":'',
  //     "custard":'',
  //     "flan":'',
  //     "sundae":'',
  //     "tart":'',
  //     "brownie":'',
  //     "fudge":'',
  //     "nougat":'',
  //     "churros":''
  //   },
  //   "health": {
  //     "vitamins":'',
  //     "minerals":'',
  //     "supplements":'',
  //     "aromatherapy":'',
  //     "allergy relief":'',
  //     "digestion aid":'',
  //     "pain killer":''
  //   }
  // },
  // "car": {
  //   "parts": {
  //     "car phone mount":'',
  //     "wipers":'',
  //     "brakes":'',
  //     "windshield":'',
  //     "radiator":'',
  //     "transmission":'',
  //     "gaskets":'',
  //     "shocks":'',
  //     "struts":'',
  //     "suspension":'',
  //     "axle":'',
  //     "exhaust":'',
  //     "ignition":''
  //   }
  // }
};
*/

function to_title_case(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
};

/**
 * Small helper to get SHALLOW copy of obj WITHOUT prop
 */
 const rmProp = (obj, prop) => ( (({[prop]:_, ...rest})=>rest)(obj) );

/**
 * Group Array by key. Root keys of a resulting array is value
 * of specified key.
 *
 * @param      {Array}   src     The source array
 * @param      {String}  key     The by key to group by
 * @return     {Object}          Object with grouped objects as values
 */
const grpBy = (src, key) => src.reduce((a, e) => (
  (a[e[key]] = a[e[key]] || []).push(rmProp(e, key)),  a
), {});

/**
 * Collapse array of object if it consists of only object with single value.
 * Replace it by the rest value.
 */
const blowObj = obj => Array.isArray(obj) && obj.length === 1 && Object.values(obj[0]).length === 1 ? Object.values(obj[0])[0] : obj;

/**
 * Recursive grouping with list of keys. `keyList` may be an array
 * of key names or comma separated list of key names whom UNIQUE values will
 * becomes the keys of the resulting object.
 */
const grpByReal = function (src, keyList) {
  const [key, ...rest] = Array.isArray(keyList) ? keyList : String(keyList).trim().split(/\s*,\s*/);
  const res = key ? grpBy(src, key) : [...src];
  if (rest.length) {
for (const k in res) {
  res[k] = grpByReal(res[k], rest)
}
  } else {
for (const k in res) {
  res[k] = blowObj(res[k])
}
  }
  return res;
};

export default function Menu () {
  const [active, set_active] = React.useState([]);
  const [menu_data, set_menu_data] = React.useState({});
  const [primary_tags, set_primary_tags] = React.useState([]);

  React.useEffect(() => {
    (async function () {
      let
      tag_list = [],
      get_tags = await api_call ('unitedshops', 'tags_get', {});
      console.log('get_tags', get_tags.tags);
      if (get_tags && get_tags.tags.length > 0) {
        let menu_parsed = JSON.parse(JSON.stringify(grpByReal(get_tags.tags, 'primary_tag, secondary_tag, tertiary_tag')).replace(/\[{}\]/g, '""'));

        delete menu_parsed.home;
        delete menu_parsed.electronics;
        delete menu_parsed.baby;
        delete menu_parsed.pets;
        delete menu_parsed.hobbies;

        console.log('menu_parsed', menu_parsed);
        set_menu_data(menu_parsed);
      };


  let primary_tags_list = [];
  Object.entries(menu_data).map(([k1,v1]) => {
    primary_tags_list.push(k1);
  });
  set_primary_tags(primary_tags_list);
//console.log('primary_tags', primary_tags);
    })();
  }, []);

  async function toggle_menu (menu_text) {
    let change;
    if (active.includes(menu_text)) {
      change = 'remove';
      set_active(active.filter(item => item != menu_text));
    } else {
      change = 'add';
      set_active([...active, menu_text])
    }
    if (change === 'remove' && primary_tags.includes(menu_text)) {
      set_active([]);
      /*
      let also_remove = [];
      Object.entries(menu_data).map(([k1,v1]) => {
        if (k1 === menu_text) {
          Object.entries(v1).map(([k2,v2]) => {
            also_remove.push(k2);
          });
          console.log('also_remove', also_remove);
          // hide accordingly
          set_active(active.filter(item => 
            !also_remove.includes(item) // hide any children
            && 
            item != menu_text // hide parent
          ));
        }
      });
      */
    }
  };

  React.useEffect(() => {
    (async function () {
      console.log('active', active);
    })();
  }, [active]);


  React.useEffect(() => {
    (async function () {
      // let get_data = await api_call ('unitedshops', 'classified_get', {
      //   tag: 'jeans',
      //   page: "1",
      //   per_page: "50",
      //   group: "umd"
      // });
      // if (get_data && get_data.products && get_data.products.length > 0) {
      //   setItems(get_data.products);
      // };
      
    })();
  }, []);

  return (
    <>
      {/* <div className={`menu-page-title`}>Menu</div> */}
      {menu_data && Object.entries(menu_data).map(([k1,v1]) => {
        // console.log('k1', k1);
        // console.log('v1', v1);
        return (
          <>
          <div className={`menu transition primary disable-selection ${k1} ${active.includes(k1) ? 'active' : ''}`} onClick={()=>{toggle_menu(k1)}}>{to_title_case(k1)}</div>
          {Object.entries(v1).map(([k2,v2]) => {
            // console.log('k2', k2);
            // console.log('v2', v2);
            return (
              <>
              <div className={`menu transition secondary disable-selection ${k1} ${active.includes(k1) ? 'active' : ''}`} onClick={()=>{toggle_menu(k2)}}>{to_title_case(k2)}</div>
              {Object.entries(v2).map(([k3,v3]) => {
                //console.log('k3', k3);
                //console.log('v3', v3);
                return (
                  <div>
                  <a className={`menu transition tertiary disable-selection ${k2} ${active.includes(k2) ? 'active' : ''}`} href={`/products?tag=${k3.replace(/\s+/g,'_')}`}>{to_title_case(k3)}</a>
                  {/* <LinkTo to={`/tag/${k3}`}>{to_title_case(k3)}</LinkTo> */}
                  </div>
                )
              })}
              </>
            )
          })}
          </>
        )
      })
      }
      {/* {menu_data && Object.entries(menu_data).forEach(([k1,v1]) => {
        console.log('k1', k1);
        console.log('v1', v1);
        return (
          <>
          <p>{k1}</p>
          {Object.entries(v1).forEach(([k2,v2]) => {
            console.log('k2', k2);
            console.log('v2', v2);
            return (
              <p>{k2}</p>
            )
          })}
          </>
        )
      })
      } */}
    </>
  );
};