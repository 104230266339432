import prune from 'json-prune'

function lowercase_obj_values(obj) {
  Object.keys(obj).forEach(k => {
    if (obj[k] == null || obj[k] == undefined || obj[k] == '') {
      return false;
    }

    // do not lowercase values from these fields
    if (k.match('password|_id|name|address|title|position|city|state|category|subject|body') != null) {
      return;
    }
    // if (typeof obj[k] == 'string') {
    //   obj[k] = obj[k].toLowerCase();
    // } else if (typeof obj[k] == 'object') {
    //   lowercase_obj_values(obj[k]);
    // }
  });
  return obj;
}

function is_type (type, val) {
  return val != null ? !!(val.constructor && val.constructor.name.toLowerCase() === type.toLowerCase()) : null
};
/*
is_type('object', {})
true
is_type('object', [])
false
is_type('array', [])
true
is_type('array', {})
false
is_type('string', '')
true
is_type('string', 1)
false
is_type('number', '')
false
is_type('number', 1)
true
is_type('boolean', 1)
false
is_type('boolean', true)
true
*/

function strigify_nested_obj (obj) {
  let strigified_nested_obj = {};
  Object.entries(obj).forEach(([key, value]) => {
    //console.log(key);
    //console.log(value);
    if (is_type('object', value)) {
      value = prune(value).replace(/"/g,"'")
    }
    // create new object
    strigified_nested_obj[key] = value;
  });
  return strigified_nested_obj;
};

function iso_time () {
  return new Date().toISOString().replace('T', ' ').replace('Z', '000');
}

function wait(time) {
  return new Promise(resolve => {
      setTimeout(() => {
          resolve();
      }, time);
  });
};

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

function to_title_case(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
};

function url_params_obj (url_query) {
  if (url_query.includes('?')) {
    url_query = url_query.substring(1)
  }
  function params_to_object(entries) {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const url_params = new URLSearchParams(url_query);
  const entries = url_params.entries();
  const params = params_to_object(entries);
  return params;
}

function format_currency (input) {
  return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(input);
}

function unique_array (arr) {
  return arr.reduce(function(a,b){if(a.indexOf(b)<0)a.push(b);return a;},[]);
};

function years_ago (timestamp) {
  return Number(( (new Date().getTime() - new Date(timestamp).getTime()) / (24 * 60 * 60 * 1000 * 365) ).toFixed(1))
}

function time_duration (seconds) {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
}

export {lowercase_obj_values, is_type, strigify_nested_obj, iso_time, wait, capitalize, to_title_case, url_params_obj, format_currency, unique_array, years_ago, time_duration}