// https://posthog.com/docs/integrations/js-integration
export default function postHogPlugin(userConfig) {
  // return object for analytics to use
  let isPostHogLoaded = false
  return {
    /* All plugins require a name */
    name: 'posthog',
    /* Everything else below this is optional depending on your plugin requirements */
    config: {
      token: userConfig.token,
      api_host: userConfig.api_host,
      otherOption: userConfig.otherOption
    },
    initialize: ({ config }) => {
      // load provider script to page. https://posthog.com/docs/integrations/js-integration#installation
      !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
      posthog.init(config.token /*'[your-token]' */, {
        api_host: config.api_host, /* 'https://[your-instance]' */
        loaded: function(posthog) {
          isPostHogLoaded = true
        }
      })
    },
    page: ({ payload }) => {
      // call provider specific page tracking
    },
    track: ({ payload }) => {
      // call provider specific event tracking
      posthog.capture(payload.event, payload.properties);
    },
    identify: ({ payload }) => {
      const { userId, traits } = payload
      if (typeof userId === 'string') {
        posthog.identify(userId);
      }
    },
    reset: () => {
      posthog.reset();
    },
    loaded: () => {
      // return boolean so analytics knows when it can send data to third party
      return isPostHogLoaded
    }
  }
}