import React from 'react';
import style from './style.scss';
import Form from '@rjsf/material-ui';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as LinkTo,
  useLocation,
  useParams,
  useHistory
} from 'react-router-dom';
import combineLocalStorage from './combineLocalStorage';
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
  CssBaseline,
  Grid,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Button,
  ButtonGroup,
  Typography,
  CircularProgress,
  Backdrop,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  useLocalStorage,
  writeStorage
} from '@rehooks/local-storage';
import useClipboard from 'react-use-clipboard';
import { useAnalytics } from 'use-analytics';
import { useScrollTracker } from 'react-scroll-tracker';
import {
  api_call
} from './api_endpoints';
import {
  format_currency,
  capitalize,
  wait
} from './util';
//import console from 'console';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    //maxWidth: 1280,
    //marginTop: '40px',
    //marginBottom: '40px'
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(4)
  },
  paperSlim: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4)
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    width: '100%'
  },
  buttonProgress: {
    color: theme.palette.primary1Color,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Labeling (props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [loading, set_loading] = React.useState({});
  const [page, set_page] = React.useState('label_data');
  const [tags, set_tags] = React.useState([]);
  const [selected_tag, set_selected_tag] = React.useState();
  const [filters, set_filters] = React.useState({});
  const [count, set_count] = React.useState(0);
  const [products, set_products] = React.useState([]);
  const [stats, set_stats] = React.useState([]);
  const [exclude, set_exclude] = React.useState([]);
  const [remove, set_remove] = React.useState([]);
  const [test_add, set_test_add] = React.useState([]);
  const [test_remove, set_test_remove] = React.useState([]);
  const [test_data, set_test_data] = React.useState([]);
  const [include, set_include] = React.useState([]);
  const [incorrect, set_incorrect] = React.useState([]);
  const [discard, set_discard] = React.useState([]);
  const [hidden, set_hidden] = React.useState([]);

  React.useEffect(() => {

    (async function () {
      // console.log('test');
      set_loading({
        loading: true
      })
      let
      tag_list = [],
      get_tags = await api_call ('unitedshops', 'tags_get', {});
      //console.log('get_tags', get_tags);
      if (get_tags && get_tags.tags.length > 0) {
        get_tags.tags.map(tag => {
          tag_list.push(tag.tertiary_tag);
          //set_tags(current_array => [...current_array, tag.tertiary_tag]);
          //console.log('tags', tags);
        })
      }
      //console.log('tag_list', tag_list);
      set_tags(tag_list);
      //console.log('tags', tags);
      set_loading(null);

    })();
  }, []);

  React.useEffect(() => {
    (async function () {
      if (selected_tag && selected_tag !== 'SELECT TAG') {
        set_exclude([]);
        set_remove([]);
        set_test_add([]);
        set_test_remove([]);
        set_include([]);
        if (page !== 'review_labeled') {
          set_stats([])
        }
        // get products
        await get_products(selected_tag);
      }
    })();
  }, [page]);

  React.useEffect(() => {
    let list = [];
    test_add.map(p => {
      list.push([
        p.domain,
        p.product_id,
        p.tertiary_tag
      ])
    })
    set_test_data(list);
  }, [test_add]);

  React.useEffect(() => {
    console.log('use effect remove', test_remove);
  }, [test_remove]);

  // React.useEffect(() => {
  //   console.log('hidden', hidden);
  // }, [hidden]);

  async function get_products (tag, filters) {

    console.log('page', page);

    set_loading({
      loading: true
    });

    if (['amazon_all', 'amazon_filtered'].includes(page)) {
      let get_data = await api_call ('unitedshops', page, {
        tag: tag
      });
      if (get_data && get_data.products && get_data.products.length > 0) {
        set_products(get_data.products);
        set_loading(null);
      }
      return;
    }

    if (page.includes('a21') || page.includes('b21')) {
      let get_data = await api_call ('unitedshops', 'classified_get', {
        tag: tag,
        page: filters && filters.page ? String(filters.page) : "1",
        per_page: filters && filters.per_page ? String(filters.per_page) : "500",
        group: page.includes('a21') ? "umd" : "wsu"
      });
      if (get_data && get_data.products && get_data.products.length > 0) {
        set_products(get_data.products);
        set_loading(null);
      }
      return;
    }

    //console.log('get_products', tag);
    let
    get_count = await api_call ('unitedshops', 'labeling_count', {
      tag: tag
    });

    let get_params = {
      tag: tag,
      count: filters && filters.count ? filters.count : 50
    };

    // include filters if filled in
    filters && filters.exclude_keywords && filters.exclude_keywords.length > 0 ? get_params.exclude_keywords = filters.exclude_keywords.replace(/[, ]+/g, ' ').trim().replace(/ /g, '|') : null;
    filters && filters.min_price && filters.min_price.length > 0 ? get_params.min_price = filters.min_price : null;
    filters && filters.exclude_domains && filters.exclude_domains.length > 0 ? get_params.exclude_domains = filters.exclude_domains.replace(/[, ]+/g, ' ').trim().replace(/ /g, '|') : null;

    let get_labeling = await api_call ('unitedshops', `${page === 'label_data' ? 'labeling_get' : page === 'review_labeled' ? 'labeled_get' : page === 'review_test' ? 'test_get' : ''}`, get_params);
    //console.log('get_labeling', get_labeling);
    if (get_labeling && get_labeling.products && get_labeling.products.length > 0) {
      set_products(get_labeling.products);
      set_loading(null);
    }
    if (get_labeling && get_labeling.stats && get_labeling.stats.length > 0) {
      set_stats(get_labeling.stats);
      set_loading(null);
    }
    if (get_count && get_count.counts) {
      set_count(`${get_count.counts[0].count}`);
    }
    if (get_labeling && get_labeling.total && get_labeling.total.length > 0) {
      set_count(get_labeling.total[0].count);
      set_loading(null);
    }
  }

  async function select_tag (tag) {
    //console.log('filters', filters);
    // reset filters & products
    set_filters({});
    set_products([]);
    // apply new filters & products
    if (tag === 'SELECT TAG') {
      set_selected_tag(null);
    } else {
      set_selected_tag(tag);
      await get_products(tag);
    }
  }

  async function process_filters () {
    await get_products(selected_tag, filters);
  }

  async function toggle_exclude (product_id) {
    if (exclude.includes(product_id)) {
      set_exclude(exclude.filter(item => item != product_id));
    } else {
      set_exclude([...exclude, product_id])
    }
  }

  async function save () {
    set_loading({
      saving: true
    });
    //console.log('exclude', exclude);
    let save_list = [];
    products.map(product => {
      if (!exclude.includes(product.product_id)) {
        save_list.push([
          product.domain,
          product.product_id,
          selected_tag
        ])
      }
    });
    //console.log('save_list', save_list, JSON.stringify(save_list));
    if (!save_list || !save_list.length > 0) {
      set_loading({});
    } else {
      let create_labeling = await api_call ('unitedshops', 'labeling_create', {
        labels: JSON.stringify(save_list)
      });
      if (create_labeling && create_labeling.response_status && create_labeling.response_status === 'success') {
        set_loading({
          saved: true
        });
        await get_products(selected_tag, filters);
        await wait(1000);
        set_loading({});
      }
    }
  }

  async function toggle_remove (product_id) {
    if (remove.includes(product_id)) {
      set_remove(remove.filter(item => item != product_id));
    } else {
      set_remove([...remove, product_id])
    }
  }

  async function remove_process () {
    if (remove && remove.length > 0) {
      set_loading({
        removing: true
      });
      let remove_labeled = await api_call ('unitedshops', 'labeled_remove', {
        product_id: JSON.stringify(remove)
      });
      if (remove_labeled && remove_labeled.response_status && remove_labeled.response_status === 'success') {
        set_products(products.filter(p => !remove.includes(p.product_id)));
        set_loading({
          removed: true
        });
        set_remove([]);
        await wait(500);
        set_loading({});
      }
    }
  }

  const array_includes_object = (arr, key, value_check) => {
    return arr.some(value => value[key] === value_check);
  }

  async function toggle_test_add (domain, product_id, tertiary_tag) {
    if (array_includes_object(test_add, 'product_id', product_id)) {
      set_test_add(test_add.filter(obj => obj.product_id != product_id));
    } else {
      set_test_add([...test_add, {
        domain: domain,
        product_id: product_id,
        tertiary_tag: tertiary_tag
      }])
    }
    console.log('add', product_id);
  }

  async function toggle_test_remove (domain, product_id, tertiary_tag) {
    if (array_includes_object(test_remove, 'product_id', product_id)) {
      set_test_remove(test_remove.filter(obj => obj.product_id != product_id));
    } else {
      set_test_remove([...test_remove, {
        domain: domain,
        product_id: product_id,
        tertiary_tag: tertiary_tag
      }])
    }
    console.log('remove', product_id);
  }

  async function test_add_process () {
    if (test_add && test_add.length > 0) {
      set_loading({
        saving: true
      });
      let add_products = [];
      test_add.map(p => {
        add_products.push(p.product_id);
      })
      let create_test = await api_call ('unitedshops', 'test_create', {
        tests: JSON.stringify(test_data)
      });
      if (create_test && create_test.response_status && create_test.response_status === 'success') {
        // hide added products
        set_products(products.filter(p => !array_includes_object(test_add, 'product_id', p.product_id)));
        set_loading({
          saved: true
        });
        set_test_add([]);
        await wait(2000);
        set_loading({});
      }
    }
  }

  async function test_remove_process () {
    if (test_remove && test_remove.length > 0) {
      set_loading({
        removing: true
      });
      let remove_products = [];
      test_remove.map(p => {
        remove_products.push(p.product_id);
      })
      let create_test = await api_call ('unitedshops', 'test_remove', {
        product_id: JSON.stringify(remove_products)
      });
      if (create_test && create_test.response_status && create_test.response_status === 'success') {
        // hide removed products
        set_products(products.filter(p => !array_includes_object(test_remove, 'product_id', p.product_id)));
        set_loading({
          removed: true
        });
        set_test_remove([]);
        await wait(2000);
        set_loading({});
      }
    }
  }

  async function toggle_include (product_id) {
    if (include.includes(product_id)) {
      set_include(include.filter(item => item != product_id));
    } else {
      set_include([...include, product_id])
    }
  };

  async function save_include () {
    set_loading({
      saving: true
    });
    //console.log('exclude', exclude);
    let save_list = [];
    products.map(product => {
      if (include.includes(product.id)) {
        save_list.push([
          'amazon.com',
          product.id,
          selected_tag
        ])
      }
    });
    //console.log('save_list', save_list, JSON.stringify(save_list));
    if (!save_list || !save_list.length > 0) {
      set_loading({});
    } else {
      let create_labeling = await api_call ('unitedshops', 'labeling_create', {
        labels: JSON.stringify(save_list)
      });
      if (create_labeling && create_labeling.response_status && create_labeling.response_status === 'success') {
        set_loading({
          saved: true
        });
        await get_products(selected_tag, filters);
        await wait(1000);
        set_loading({});
      }
    }
  }

  async function assess_classified (assessment, product_id) {

    set_loading({
      saving: true
    });

    let flag;
    if (assessment === 'incorrect') {
      if (incorrect.includes(product_id)) {
        set_incorrect(incorrect.filter(item => item != product_id));
      } else {
        set_incorrect([...incorrect, product_id])
      }
      // flag below is reverse because of the delay in update to the "incorrect" usestate array
      if (incorrect.find(i => i === product_id) != undefined) {
        flag = false;
      } else {
        flag = true;
      }
    }

    if (assessment === 'discard') {
      if (discard.includes(product_id)) {
        set_discard(discard.filter(item => item != product_id));
      } else {
        set_discard([...discard, product_id])
      }
      // flag below is reverse because of the delay in update to the "incorrect" usestate array
      if (discard.find(i => i === product_id) != undefined) {
        flag = false;
      } else {
        flag = true;
      }
    }
    

    console.log('assess_classified', assessment, product_id, flag);

    console.log('obj', {
      product_id: product_id,
      [assessment]: flag,
      group: page.match(/a\d/) != null ? 'umd' : 'wsu'
    });

    let run_command = await api_call ('unitedshops', 'classified_assess', {
      product_id: product_id,
      [assessment]: String(flag),
      group: page.match(/a\d/) != null ? 'umd' : 'wsu'
    });
    if (run_command && run_command.response_status && run_command.response_status === 'success') {
      set_loading({
        saved: true
      });
      await wait(500);
      set_loading({});
    }

  };

  async function toggle_discard (product_id) {
  };

  return ( 
    <React.Fragment>
      
      <h1>UnitedShops.org</h1>
      <h2>Training & Test Data</h2>
      
      <div className={`center`}>
        <select className={`select-tag`} onChange={e => set_page(e.target.value)}>
            <option key={`label_data`} value={`label_data`}>Label Data</option>
            <option key={`review_labeled`} value={`review_labeled`}>Review Training</option>
            <option key={`review_test`} value={`review_test`}>Review Test</option>

            <option key={`amazon_all`} value={`amazon_all`}>Amazon All</option>
            <option key={`amazon_filtered`} value={`amazon_filtered`}>Amazon Filtered</option>

            <option key={`a21_classified`} value={`a21_classified`}>A21 Classified</option>
            <option key={`b21_classified`} value={`b21_classified`}>B21 Classified</option>
            
        </select>

        <select className={`select-tag`} onChange={e => select_tag(e.target.value)}>
          <option key={`select_tag`}>SELECT TAG</option>
        {
          tags && tags.length > 0 && tags.map(tag => {
            return (<option key={tag} value={tag}>{tag}</option>)
          })
        }
        </select>

        { selected_tag && page === 'label_data' && <div className={`filters`}>
          <select className={`count-dropdown`} defaultValue={50} onChange={e => set_filters({
            ...filters,
            count: e.target.value
          })}>
            <option key={50} value={50}>50</option>
            <option key={100} value={100}>100</option>
            <option key={200} value={200}>200</option>
          </select>
          <input className={`filter exclude-keywords`} placeholder={`exclude keywords`} value={filters.exclude_keywords ? filters.exclude_keywords : ''}  onChange={e => {set_filters({
            ...filters,
            exclude_keywords: e.target.value
          })}} />
          <input className={`filter min-price`} placeholder={`min price`} type={`number`} value={filters.min_price ? filters.min_price : ''}  onChange={e => {set_filters({
            ...filters,
            min_price: e.target.value
          })}} />
          <input className={`filter`} placeholder={`exclude domains`} value={filters.exclude_domains ? filters.exclude_domains : ''} onChange={e => {set_filters({
            ...filters,
            exclude_domains: e.target.value
          })}} />
          <button onClick={e => {process_filters()}}>Filter</button>
        </div>}


        { selected_tag && page.includes('classified') && <div className={`filters`}>
          <input className={`filter page`} placeholder={`page`} type={`number`} value={filters.page ? filters.page : ''}  onChange={e => {set_filters({
            ...filters,
            page: e.target.value
          })}} />
          <input className={`filter per_page`} placeholder={`per page`} type={`number`} value={filters.per_page ? filters.per_page : ''}  onChange={e => {set_filters({
            ...filters,
            per_page: e.target.value
          })}} />
          <button onClick={e => {process_filters()}}>Go</button>
        </div>}

        <div className={`${Number(count) === 0 ? 'hidden' : ''}`}>

        { selected_tag && count && !loading && page != 'review_test' && !page.includes('classified') && <span className={`count`}>{Intl.NumberFormat().format(count)} already labeled.</span> }

        { selected_tag && count && !loading && page === 'review_test' && !page.includes('classified') && <span className={`count`}>{Intl.NumberFormat().format(count)} test data for tag.</span> }
        
        </div>

      </div>

      <div className={`block`}></div>

      { products && products.length === 0 && !selected_tag && <div className={`start center`}>Select tag to start.</div> }


      { stats && stats.length > 0 && !loading && 
        <table className={`stats`}>
          <tr className={`stats-header`}>
            <td>Count</td>
            <td>Domain</td>
          </tr>
          {
            stats.map(stat => {
              return (
                <>
                  <tr>
                    <td className={`stats-count`}>{stat.count}</td>
                    <td>{stat.domain}</td>
                  </tr>
                </>
              )
            })
          }
        </table>
      }

      {
        products && products.length > 0 && !['amazon_all', 'amazon_filtered'].includes(page) && products.map(product => {
          return (
            <>
            
            <div className={`${exclude.includes(product.product_id) || remove.includes(product.product_id) ? 'product-box excluded' : array_includes_object(test_add, 'product_id', product.product_id) || array_includes_object(test_remove, 'product_id', product.product_id) ? 'product-box test' : 'product-box'} ${hidden.includes(product.product_id) ? 'hidden' : ''}`} key={product.product_id} id={product.product_id}>
              
              <img className={`image-loader`} src={product.image_1} onError={()=> {set_hidden([...hidden, product.product_id])}} />

              <a target="_blank" href={`https://${product.domain}/products/${product.handle}`} title={product.description}>
              <div className={`product-image`} style={{backgroundImage: `url('${product.image_1}')`}}></div>
              </a>

              {page.includes('classified') && product.confidence_score &&
              <div className={`product-small`}>
                {product.confidence_score}<br/>
                {product.dominant_color ? `dominant color: ${product.dominant_color}` : ''}
                {product.image_used ? `image used: ${product.image_used} ` : ''}
                {product.applied_rules ? `applied_rules: ${product.applied_rules}` : ''}
              </div>}

              <span className={`product-title`}>{product.title}</span>

              <span className={`product-domain product-info`}>{product.domain}</span>

              <span className={`product-price product-info`}>{format_currency(product.price)}</span>

              <span className={`product-options product-info`}>{product.options.join(', ')}</span>

              <span className={`product-variants product-info`}>{product.variants_count} variants</span>
              
              <div className={`${page === 'label_data' ? 'center' : 'hidden'}`}>
                <button className={`exclude`} onClick={e => {toggle_exclude(product.product_id)}}>Exclude</button>
              </div>

              <div className={`${page === 'review_labeled' ? 'center' : 'hidden'}`}>
                <button className={`exclude`} onClick={e => {toggle_remove(product.product_id)}}>Remove</button>
              </div>

              <div className={`${['label_data', 'review_labeled'].includes(page) ? 'center' : 'hidden'}`}>
                <button className={`test-button`} onClick={e => {toggle_test_add(product.domain, product.product_id, selected_tag)}}>Add Test</button>
              </div>

              <div className={`${page === 'review_test' ? 'center' : 'hidden'}`}>
                <button className={`test-button`} onClick={e => {toggle_test_remove(product.domain, product.product_id, selected_tag)}}>Remove Test</button>
              </div>

              <div className={`${page.includes('classified') ? 'center' : 'hidden'}`}>
                <button className={`${incorrect.includes(product.product_id) ? 'incorrect' : ''} button-generic`} onClick={e => {assess_classified('incorrect', product.product_id)}}>Incorrect</button>
                <button className={`${discard.includes(product.product_id) ? 'discard' : ''} button-generic`} onClick={e => {assess_classified('discard', product.product_id)}}>Discard</button>
              </div>

            </div>
            </>
          )
        })
      }


      {
        products && products.length > 0 && ['amazon_all', 'amazon_filtered'].includes(page) && products.map(product => {
          return (
            <>
            
            <div key={product.id} id={product.id} className={include.includes(product.id) || product.included || remove.includes(product.id) ? `product-box test ${page}` : 'product-box'}>

              <a target="_blank" href={product.url}>

              <div className={`product-image`} style={{backgroundImage: `url('${product.image_1.replace(/_\w{1,}_.jpg/, "_SL1000_.jpg")}')`}}></div>
              </a>

              <span className={`product-title`}>{product.title}</span>

              <span className={`product-price product-info`}>{format_currency(product.price)}</span>

              <span className={`product-options product-info`}>{product.id}</span>

              <div className={page === 'amazon_all' ? 'center' : 'hidden'}>
                <button className={`test-button ${page}`} onClick={e => {toggle_include(product.id)}}>Include</button>
              </div>

              <div className={page === 'amazon_filtered' ? 'center' : 'hidden'}>
                <button className={`test-button ${page}`} onClick={e => {toggle_remove(product.id)}}>Remove</button>
              </div>

            </div>
            </>
          )
        })
      }
      
      {/* {products && products.length === 0 && selected_tag && selected_tag.length > 0 && } */}

      { loading && loading.loading && <div className={`loading`}>Loading...</div> }
      { loading && loading.saving && <div className={`loading`}>Saving...</div> }
      { loading && loading.saved && <div className={`loading`}>Saved!</div> }
      { loading && loading.removing && <div className={`loading`}>Removing...</div> }
      { loading && loading.removed && <div className={`loading`}>Removed!</div> }

      {products && products.length > 0 && page === 'label_data' &&
        <div className={`center`}>
          <button className={`save`} onClick={e => {save()}}>Save</button>
        </div>
      }

      {products && products.length > 0 && remove && remove.length > 0 && page === 'review_labeled' &&
        <div className={`fixed-bottom-container`}>
          <button className={`remove-button`} onClick={e => {remove_process()}}>Remove</button>
        </div>
      }

      {products && products.length > 0 && test_add && test_add.length > 0 && page != 'review_test' &&
        <div className={`fixed-bottom-container`}>
          <button className={`add-button`} onClick={e => {test_add_process()}}>Add Test Data</button>
        </div>
      }

      {products && products.length > 0 && test_remove && test_remove.length > 0 && page === 'review_test' &&
        <div className={`fixed-bottom-container`}>
          <button className={`remove-button`} onClick={e => {test_remove_process()}}>Remove Test Data</button>
        </div>
      }

      
      {products && products.length > 0 && page === 'amazon_all' &&
        <div className={`fixed-bottom-container`}>
          <button className={`add-button`} onClick={e => {save_include()}}>Save</button>
        </div>
      }
      {products && products.length > 0 && page === 'amazon_filtered' &&
        <div className={`fixed-bottom-container`}>
          <button className={`remove-button`} onClick={e => {remove_process()}}>Remove</button>
        </div>
      }
    </React.Fragment>
  );
}